import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { DailyUsersState } from "../../state/authState";

export const userVisitApi = createApi({
    reducerPath: "userVisitApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/v1/accounts" }),
    endpoints: (builder) => ({
        getUserVisit: builder.query<DailyUsersState, void>({
            query: () => `/daily_users_visit/`,
        }),
    }),
    refetchOnMountOrArgChange: false, // Applies globally to all endpoints
    keepUnusedDataFor: 300,
});


export const { useGetUserVisitQuery } = userVisitApi;