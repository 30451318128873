import ReactDOM from "react-dom/client";
import { useState } from "react";
import "./index.css";
import App from "./App";
// in your main JavaScript file (e.g., index.js or App.js)
import '@fortawesome/fontawesome-free/css/all.min.css';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
// import store from './app/store';
import { store } from "./app/store";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <Header />
//       <App />
//       <Footer />
//     </BrowserRouter>
//   </Provider>
// );



const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const Index = () => {
  const userLocation = localStorage.getItem("userLocation")
    ? JSON.parse(localStorage.getItem("userLocation") as string)
    : null;
  const [locationUser, setLocationUser] = useState(userLocation);

  const handleUserLocationData = (data: any) => {
    setLocationUser(data);
  };
  // console.clear();

  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <BrowserRouter>
          <Header handleUserLocationData={handleUserLocationData} />
          <App locationUser={locationUser} />
          <Footer locationUser={locationUser} />
        </BrowserRouter>
      {/* </PersistGate> */}
    </Provider>
  );
};

root.render(<Index />);
