import {
  configureStore,
  StateFromReducersMapObject,
  PreloadedState,
} from "@reduxjs/toolkit";
import {
  categoryBrandListReducer,
  brandSeriesListReducer,
  productListReducer,
  productSeriesListReducer,
  SellingBrandListReducer,
  SellingBrandProductListReducer,
} from "../features/brandSlice";
import {
  MetaListReducer, LinkReducer, MetaNewReducer, ReviewReducer, BlogMetaReducer,
} from "../features/seoSlice";
import { categoryApi } from "../actions/api/categorySlice";
import { BannerListReducer } from "../features/bannerSlice";
import { excitingOffersReducer } from "../features/excitingOfferSlice";
import { TestimonialReducer } from "../features/testimonialSlice";
import { BlogReducer, BlogDetailReducer } from "../features/blogSlice";
import {
  processorListReducer,
  ramListReducer,
  graphicCardListReducer,
  screenListReducer,
  modelYearListReducer,
  hardDiskListReducer,
} from "../features/laptopExtrasSlice";
import { bePartnerReducer, contactUsReducer } from "../features/backendSlice"
import { QuestionnaireListReducer, LocationWeighageReducer } from "../features/questionnaireSlice";
import {
  OrderInitReducer,
  CreateIncomplteOrderReducer,
  UpdateOrderReducer,
  userOrderListReducer,
  orderDetailsReducer,
  deviceReportReducer,
  orderInquiryReducer,
  OrderCancelReducer,
  ordersCountPriceReducer,

} from "../features/orderSlice";
import {
  StateWithCitiesReducer,
  cityListReducer,
} from "../features/fixtureSlice";
import {
  ProductDetailReducer,
  excitingOfferListReducer,
  SearchProductReducer,
  ProductVariantReducer
} from "../features/productSlice";
import { categoryListReducer } from "../features/categorySlice";
import { WarrantyListReducer } from "../features/warrantySlice";
import {
  userLoginReducer,
  UserLogOutReducer,
  sendOtpReducer,
  loginOtpReducer,
  OtpUserRegistrationReducer,
  deleteUserReducer,
  updateUserReducer,
  addUserAddressReducer,
  getUserAddressReducer,
  updateUserAddressReducer,
  deleteUserAddressReducer,
  changeUserPasswordReducer,
  dailyUsersVisitReducer,
  NewUserRegisterationReducer,
} from "../features/accountSlice";
import authReducer from "../features/authSlice";
import { apiSlice } from "../actions/api/apiSlice";
import { bannerApi } from "../actions/api/bannerSlice";
import { excitingOfferApi } from "../actions/api/excitingOfferSlice";
import { getAuthData } from "../actions/auth";
import { seoApi } from "../actions/api/seoSlice";
import { testimonialApi } from "../actions/api/testimonialSlice";
import { userVisitApi } from "../actions/api/useVisitSlice";
import { orderApi } from "../actions/api/orderSlice";
import { combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root", // Key for the root of the persisted state
  storage, // Local storage for persistence
  whitelist: [
    categoryApi.reducerPath, 
    bannerApi.reducerPath,
    excitingOfferApi.reducerPath,
    seoApi.reducerPath,
    testimonialApi.reducerPath,
    userVisitApi.reducerPath,
    orderApi.reducerPath,
    // Add other slices if needed
  ],
};


const reducers = {
  [apiSlice.reducerPath]: apiSlice.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [bannerApi.reducerPath]: bannerApi.reducer,
  [excitingOfferApi.reducerPath]: excitingOfferApi.reducer,
  [seoApi.reducerPath]: seoApi.reducer,
  [testimonialApi.reducerPath]: testimonialApi.reducer,
  [userVisitApi.reducerPath]: userVisitApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  auth: authReducer,
  userLogin: userLoginReducer,
  userLogOut: UserLogOutReducer,
  sendOtp: sendOtpReducer,
  loginOtp: loginOtpReducer,
  OtpUserRegitration: OtpUserRegistrationReducer,
  NewUserRegisterations: NewUserRegisterationReducer,
  deleteUser: deleteUserReducer,
  dailyUsersVisit: dailyUsersVisitReducer,
  updateUser: updateUserReducer,
  addUserAddress: addUserAddressReducer,
  getUserAddress: getUserAddressReducer,
  updateUserAddress: updateUserAddressReducer,
  deleteUserAddress: deleteUserAddressReducer,
  changeUserPassword: changeUserPasswordReducer,
  categoryBrandList: categoryBrandListReducer,
  categoryList: categoryListReducer,
  brandSeriesList: brandSeriesListReducer,
  brandProductSeriesList: productSeriesListReducer,
  brandProductList: productListReducer,
  sellingBrandList: SellingBrandListReducer,
  sellingBrandProductList: SellingBrandProductListReducer,
  productDetails: ProductDetailReducer,
  productVariant: ProductVariantReducer,
  searchProduct: SearchProductReducer,
  excitingOfferList: excitingOfferListReducer,
  excitingOffers: excitingOffersReducer,
  orderInit: OrderInitReducer,
  createIncomplteOrder: CreateIncomplteOrderReducer,
  updateOrder: UpdateOrderReducer,
  cancelOrder: OrderCancelReducer,
  deviceReport: deviceReportReducer,
  userOrderList: userOrderListReducer,
  orderDetails: orderDetailsReducer,
  orderInquiry: orderInquiryReducer,
  ordersCountPrice: ordersCountPriceReducer,
  questionnaireList: QuestionnaireListReducer,
  locationForWeightage: LocationWeighageReducer,
  warrantList: WarrantyListReducer,
  stateWithCity: StateWithCitiesReducer,
  cityList: cityListReducer,
  modelYearList: modelYearListReducer,
  screenList: screenListReducer,
  graphicCardList: graphicCardListReducer,
  processorList: processorListReducer,
  ramList: ramListReducer,
  hardDiskList: hardDiskListReducer,
  contactUs: contactUsReducer,
  bePartner: bePartnerReducer,
  bannerList: BannerListReducer,
  metas: MetaListReducer,
  metas_new: MetaNewReducer,
  blog_meta: BlogMetaReducer,
  review: ReviewReducer,
  link: LinkReducer,
  testimonials: TestimonialReducer,
  blogs: BlogReducer,
  blog: BlogDetailReducer,
};

const rootReducer = combineReducers({
  ...reducers, // Spread the reducers object here
});

// Helper function to validate a base64 string
const isValidBase64 = (str: string | null): boolean => {
  if (!str) {
    return false; // Null or undefined input is not valid base64
  }

  try {
    // Try to decode and then re-encode the string to check its validity
    return btoa(atob(str)) === str;
  } catch (err) {
    return false; // If an error is thrown, it's not valid base64
  }
};

// Helper function to validate JSON string
const isValidJSON = (str: string): boolean => {
  try {
    JSON.parse(str); // Try to parse the string as JSON
    return true; // If parsing succeeds, it is valid JSON
  } catch (e) {
    return false; // If an error occurs, it is not valid JSON
  }
};


// Initialize order info from storage with validation
const initOrderInfoFromStorage = (() => {
  const encodedOrder = localStorage.getItem("order");
  if (encodedOrder && isValidBase64(encodedOrder)) {
    const decodedOrder = atob(encodedOrder);
    if (isValidJSON(decodedOrder)) {
      return JSON.parse(decodedOrder); // Decode and parse only if valid JSON
    }
  }
  return null; // Return null if any validation fails
})();

// const initOrderInfoFromStorage = localStorage.getItem("order")
//   ? JSON.parse(atob(localStorage.getItem("order") as string))
//   : null;


const authData = getAuthData();

const initialState = {
  orderInit: {
    order_details: initOrderInfoFromStorage,
    loading: false,
    error: null,
    success: true,
  },
  userLogin: {
    user_info: authData,
    loading: false,
    error: null,
    success: authData ? true : undefined,
  },
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export type RootState = StateFromReducersMapObject<typeof reducers>;

const configure = () => {
  const store = configureStore({
    reducer: reducers,
    preloadedState: initialState as unknown as PreloadedState<RootState>,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        categoryApi.middleware,
        bannerApi.middleware,
        excitingOfferApi.middleware,
        seoApi.middleware,
        testimonialApi.middleware,
        userVisitApi.middleware,
        orderApi.middleware
      ),

    // if we are in production make it false
    devTools: true,
  });
  return store;
};

export type AppStore = ReturnType<typeof configure>;
export type AppDispatch = AppStore["dispatch"];
export const store = configure();
// export const persistor = persistStore(store);
