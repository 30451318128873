import React, { useEffect, MouseEvent, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Modal } from "react-bootstrap";
import "./loginmodal.scss";
import { useState, FormEvent, ChangeEvent } from "react";
import { Button } from "reactstrap";

import {
    loginWithOtpAction,
    loginWithPasswordAction,
    sendOtpToMobileAction,
    userRegistrationAction,
} from "../../actions/accountsActions";
import NewSignUpModal from "./NewSignUpModal";
import SentOtpModal from "./SentOtpModal";
import LoginWithPassWordModal from "./LoginPassword";

import { useForm } from "react-hook-form";

interface ModalProps {
    show: boolean;
    handleClose: () => void;
}

const NewLoginModal: React.FC<ModalProps> = ({ show, handleClose }) => {
    const dispatch = useAppDispatch();
    const [authChoice, setAuthChoice] = useState<"login" | "signup" | "loginWithOtp">("loginWithOtp");
    const [otpLogin, setOtpLogin] = useState(Array(6).fill(""));
    const [otpLoginBoxError, setOtpLoginBoxError] = useState<boolean[]>(Array(6).fill(false));
    const [loginForm, setLoginForm] = useState({
        phone_number: "",
        password: "",
        otp: "",
    });
    const inputs = useRef<(HTMLInputElement | null)[]>([]);
    const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);
    const [timer, setTimer] = useState<number>(20);
    const userInfo = useAppSelector((state) => state.userLogin);
    const {
        error: loginError,
        loading: isLoginLoading,
        success: loginSuccess,
    } = userInfo;
    const loginOtp = useAppSelector((state) => state.loginOtp);
    const {
        success: otpLoginSuccess,
        loading: isOtpLoading,
        error: otpLoginError,
    } = loginOtp;

    const sendOtpToMobile = useAppSelector((state) => state.sendOtp);
    const {
        detail,
        error: isError,
        loading: isLoadingOtp,
        success: isSuccess,
        otpSuccess,
    } = sendOtpToMobile;
    const create_user_otp = useAppSelector((state) => state.OtpUserRegitration);
    const {
        loading: isUserLoading,
        error: isUserError,
        success: isUserSuccess,
        detail: isUserDetail,
    } = create_user_otp;
    const create_user_account = useAppSelector((state) => state.NewUserRegisterations);
    const { loading: isCreateUserLoading, error: isCreateUserError, success: isCreateUserSuccess } = create_user_account

    const switchToAuth = (choice: "login" | "signup" | "loginWithOtp") => {
        setAuthChoice(choice);
    };

    const {
        control,
        setError,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({ defaultValues: { phone_number: "", name: "", email: "", password: "" } });

    // login using password
    const loginFormHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const userParams = {
            phone_number: loginForm.phone_number,
            password: loginForm.password,
        };
        if (userParams) {
            dispatch(loginWithPasswordAction(userParams));
        }
    };
    // login using otp
    const loginWithOtpHandler = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const updatedErrors = otpLogin.map((digit) => digit.trim() === "");
        setOtpLoginBoxError(updatedErrors); // Set errors for empty fields

        if (!updatedErrors.includes(true)) {
            const loginParams = {
                phone_number: loginForm.phone_number,
                otp: Number(loginForm.otp),
            };
            dispatch(loginWithOtpAction(loginParams));
            // Proceed with submission logic
        }
        // const loginParams = {
        //     phone_number: loginForm.phone_number,
        //     otp: Number(loginForm.otp),
        // };
        // if (loginParams) {
        //     dispatch(loginWithOtpAction(loginParams));
        // }
    };

    // const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    //     const { name, value } = e.target;
    //     setLoginForm({ ...loginForm, [name]: value });
    // };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index?: number) => {
        const { name, value } = e.target;
        if (name === "otp" && index !== undefined) {
            const updatedOtp = [...otpLogin];
            updatedOtp[index] = value.slice(0, 1);
            setOtpLogin(updatedOtp);

            setLoginForm({ ...loginForm, otp: updatedOtp.join("") });
            const updatedErrors = [...otpLoginBoxError];
            updatedErrors[index] = false;
            setOtpLoginBoxError(updatedErrors);

            if (value && index < 5) {
                inputs.current[index + 1]?.focus();
            }
        } else {
            setLoginForm({ ...loginForm, [name]: value });
        }
    };


    const userRegistrationHandleSubmit = (data: any) => {
        setLoginForm({ ...loginForm, phone_number: data?.phone_number });
        if (Object.values(data).every((field: any) => field.length > 0)) {
            let params = {
                phone_number: data?.phone_number,
                name: data?.name,
                email: data?.email,
                password: data?.password,
            };
            dispatch(userRegistrationAction(params));
        } else {
            for (const key in data) {
                if (data[key].length === 0) {
                    setError(key as "phone_number" | "name" | "email" | "password", {
                        type: "manual",
                    });
                }
            }
        }
    };


    useEffect(() => {
        if (timer > 0) {
            const countdown = setTimeout(() => {
                setTimer(timer - 1);
            }, 1500);
            return () => clearTimeout(countdown);
        } else {
            setIsResendDisabled(false);
        }
    }, [timer]);

    useEffect(() => {
        setValue("phone_number", loginForm.phone_number || "");
    }, [loginForm.phone_number, setValue]);

    const reSendOtpClickHandler = (e: any) => {
        e.preventDefault();
        const phone_number = loginForm.phone_number;
        if (phone_number) {
            dispatch(sendOtpToMobileAction(phone_number));
        }
        setIsResendDisabled(true)
        setTimer(20)
    };

    const sendOtpClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const phone_number = loginForm.phone_number;
        if (phone_number) {
            dispatch(sendOtpToMobileAction(phone_number));
        }
        setTimer(20);
        setIsResendDisabled(true)
    };

    const resetLoginForm = () => {
        setLoginForm({
            phone_number: "",
            password: "",
            // otp: "",
            otp: "",
        });
    };

    const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
        if (e.key === "Backspace" && index > 0 && !otpLogin[index]) {
            inputs.current[index - 1]?.focus();
        }
    };

    useEffect(() => {
        if (loginSuccess || otpLoginSuccess) {
            resetLoginForm();
            setOtpLogin(Array(6).fill(""))
            reset();
            handleClose();
        }
    }, [otpLoginSuccess, loginSuccess]);

    const newSignUpProps = {
        isCreateUserError,
        isCreateUserLoading,
        isCreateUserSuccess
    }
    const UserOtpProps = {
        isSuccess,
        isUserSuccess,
        otpSuccess,
        isUserDetail,
        detail,
        isOtpLoading,
        isResendDisabled,
        timer,
        isUserError,
        isUserLoading,
        isError,
        isLoadingOtp,
        otpLoginError
    }

    const UserLoginProps = {
        loginError, isLoginLoading,
    }

    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                className="sign-up-model"
                centered
            >
                <Modal.Body className="model-body">
                    {/* Login */}
                    {authChoice === "login" &&
                        <LoginWithPassWordModal
                            switchToAuth={switchToAuth}
                            loginForm={loginForm}
                            handleInputChange={handleInputChange}
                            loginFormHandler={loginFormHandler}
                            UserLoginProps={UserLoginProps}
                        />}

                    {authChoice === "signup" &&
                        <NewSignUpModal switchToAuth={switchToAuth}
                            loginForm={loginForm}
                            handleInputChange={handleInputChange}
                            signUpProps={newSignUpProps}
                            handleSubmit={handleSubmit}
                            userRegistrationHandleSubmit={userRegistrationHandleSubmit}
                            loginOtpFormHandler={loginWithOtpHandler}
                            control={control}
                            errors={errors}
                            
                        />}
                    {authChoice === "loginWithOtp" &&
                        <SentOtpModal
                            switchToAuth={switchToAuth}
                            loginForm={loginForm}
                            otpLogin={otpLogin}
                            reSendOtpClickHandler={reSendOtpClickHandler}
                            sendOtpClickHandler={sendOtpClickHandler}
                            handleInputChange={handleInputChange}
                            userProps={UserOtpProps}
                            handleSubmit={handleSubmit}
                            userRegistrationHandleSubmit={userRegistrationHandleSubmit}
                            loginOtpFormHandler={loginWithOtpHandler}
                            handleKeyDown={handleKeyDown}
                            control={control}
                            errors={errors}
                            inputs={inputs}
                            otpLoginBoxError={otpLoginBoxError}
                        />}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>

    );
};

export default NewLoginModal;
