import React, { useEffect, useRef, useState } from "react";
import { Spinner, Input } from "reactstrap";
import { Controller } from "react-hook-form";
import { Control, FieldErrors, UseFormHandleSubmit } from "react-hook-form";
import { MouseEvent, ChangeEvent } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./loginmodal.scss";

interface UserOtpProps {
    switchToAuth: (choice: "login" | "signup" | "loginWithOtp") => void;
    handleInputChange: (e: ChangeEvent<HTMLInputElement>, index?: number) => void;
    loginOtpFormHandler: (e: MouseEvent<HTMLButtonElement>) => void;
    handleKeyDown: (e: React.KeyboardEvent, index: number) => void;
    userRegistrationHandleSubmit: (data: any) => void;
    reSendOtpClickHandler: (e: any) => void;
    sendOtpClickHandler: (e: MouseEvent<HTMLButtonElement>) => void;
    otpLogin: string[],
    otpLoginBoxError: boolean[]; // Prop for errors array
    inputs: React.RefObject<(HTMLInputElement | null)[]>;
    loginForm: {
        phone_number: string,
        password: string,
        otp: string;
        // otp: string,
    };
    handleSubmit: UseFormHandleSubmit<any>;
    control: Control<any>;
    errors: FieldErrors<any>;
    userProps: {
        isSuccess: boolean | undefined,
        isUserSuccess: boolean | undefined,
        otpSuccess: boolean | undefined,
        isUserDetail: string | null,
        detail: string | null,
        isOtpLoading: boolean,
        isResendDisabled: boolean,
        timer: number,
        otpLoginError: string | null,
        isUserError: string | null,
        isUserLoading: boolean,
        isError: string | null,
        isLoadingOtp: boolean
    };
}

const SentOtpModal: React.FC<UserOtpProps> = ({
    handleKeyDown,
    switchToAuth,
    loginForm,
    inputs,
    otpLogin,
    otpLoginBoxError,
    handleInputChange,
    reSendOtpClickHandler,
    sendOtpClickHandler,
    handleSubmit,
    loginOtpFormHandler,
    userRegistrationHandleSubmit,
    errors,
    control,
    userProps }) => {
    const {
        isSuccess,
        isUserSuccess,
        isUserDetail,
        otpSuccess,
        detail,
        isOtpLoading,
        isResendDisabled,
        otpLoginError,
        timer,
        isError,
        isUserError,
        isLoadingOtp,
        isUserLoading
    } = userProps

    useEffect(() => {
        if (otpLoginError) {
            toast.error(otpLoginError);
        }
    }, [otpLoginError]);

    return (
        <>
            <div className="overlay-container none-mob">
                <div className="overlay">
                    <div className="overlay-panel overlay-right">
                        <img
                            className="img-log"
                            src="https://buybackart-s3-prod-bucket.s3.ap-south-1.amazonaws.com/static/userLogin/otp.png"
                            alt="None"
                        />
                        <button
                            className="ghost bttn-log mb-2"
                            id="signUp"
                            onClick={() => switchToAuth("signup")}
                        >
                            Sign Up
                        </button>
                        <p> or</p>
                        <button
                            className="ghost bttn-log"
                            id="signIn"
                            onClick={() => switchToAuth("login")}
                        >
                            Sign In
                        </button>
                    </div>
                </div>
            </div>
            <div className="form-container sign-in-container">
                <form className="sign-in-form faram">
                    {/* <h3 className="heading-log">Sign in With OTP</h3> */}
                    <div className="social-container">
                        <a href="#" className="social ahref">
                            <i className="fab fa-facebook-f" />
                        </a>
                        <a href="#" className="social ahref">
                            <i className="fab fa-google-plus-g" />
                        </a>
                    </div>
                    {/* <span className="sp">or use your account</span> */}

                    {isSuccess && (
                        <>
                            {isUserSuccess || otpSuccess ? (
                                <>
                                    <h3 className="heading-log">Sign in With OTP</h3>
                                    <div className="otp-details">
                                        {
                                            isUserDetail ? (
                                                <div>{isUserDetail}</div>
                                            ) : (
                                                <div>{detail}</div>
                                            )

                                        }
                                    </div>
                                    <div className="box">
                                        {otpLogin.map((digit, index) => (
                                            <input
                                                key={index}
                                                ref={(el) => (inputs.current![index] = el)}
                                                // className="input"
                                                className={`input ${otpLoginBoxError[index] ? "input-error" : "border border-1"}`}
                                                type="number"
                                                name="otp"
                                                maxLength={1}
                                                value={digit}
                                                onChange={(e) => handleInputChange(e, index)}
                                                onKeyDown={(e) => handleKeyDown(e, index)}
                                            />
                                        ))}
                                        {/* <input
                                        required
                                        type="password"
                                        name="otp"
                                        placeholder="OTP"
                                        className="inp"
                                        value={loginForm.otp}
                                        onChange={handleInputChange}
                                    /> */}
                                        {isResendDisabled ? (
                                            <div style={{
                                                color: "#3f5bd9",
                                                marginLeft: "190px",
                                                marginTop: "-12px",
                                                marginBottom: "8px",
                                                fontSize: "15px"
                                            }}>RESEND IN: {timer}</div>
                                        ) : (
                                            <div className="resent-otp-container" onClick={reSendOtpClickHandler}>Resend OTP</div>
                                        )}

                                        <button
                                            className={`sign-up-button bttn mb-1  mt-1 ${isOtpLoading ? "dimmed-button" : ""
                                                }`}
                                            onClick={(e) => loginOtpFormHandler(e)}
                                        >
                                            {isOtpLoading && (
                                                <Spinner
                                                    animation="border"
                                                    role="status"
                                                    size="sm"
                                                    className=""
                                                />
                                            )}
                                            <span className="mx-3">Login</span>
                                        </button>
                                    </div>

                                </>
                            ) : (
                                <>
                                    <h3 className="heading-log">Create Account</h3>
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                className="react-select inp"
                                                invalid={errors.name && true}
                                                type="text"
                                                id="name"
                                                placeholder="Name"
                                                {...field}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                className="react-select inp"
                                                invalid={errors.email && true}
                                                type="email"
                                                id="email"
                                                placeholder="Email"
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="phone_number"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                className="react-select inp"
                                                invalid={errors.phone_number && true}
                                                type="text"
                                                id="phone_number"
                                                placeholder="Phone Number"
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                className="react-select inp"
                                                invalid={errors.password && true}
                                                type="password"
                                                id="password"
                                                placeholder="Enter Password"
                                                {...field}
                                            />
                                        )}
                                    />
                                    {isUserError && <div>{isUserError}</div>}
                                    <button
                                        className={`bttn mt-2 ${isUserLoading ? "dimmed-button" : ""
                                            }`}
                                        onClick={handleSubmit(userRegistrationHandleSubmit)}
                                    >
                                        {isUserLoading && (
                                            <Spinner
                                                animation="border"
                                                role="status"
                                                size="sm"
                                                className=""
                                            />
                                        )}
                                        <span className="mx-3">SignUp</span>
                                    </button>
                                </>
                            )}
                        </>
                    )}

                    {!otpSuccess && !isSuccess && (
                        <>
                            <h3 className="heading-log">Sign in With OTP</h3>
                            <input
                                required
                                type="phone_number"
                                placeholder="Mobile No."
                                name="phone_number"
                                className="inp"
                                value={loginForm.phone_number}
                                onChange={handleInputChange}
                            />
                            {isError && <div>{isError}</div>}
                            <button
                                onClick={(e) => sendOtpClickHandler(e)}
                                className={`sign-up-button bttn mb-1 mt-2 ${isLoadingOtp ? "dimmed-button" : ""
                                    }`}
                            >
                                {isLoadingOtp && (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        size="sm"
                                        className=""
                                    />
                                )}
                                <span className="">Request OTP</span>
                            </button>
                            <p className="none-bs"> or</p>

                            <button
                                className="sign-up-button bttn none-bs"
                                id="signUp"
                                onClick={() => switchToAuth("signup")}
                            >
                                Sign Up
                            </button>
                            <button
                                className="sign-up-button bttn mb-1 none-bs"
                                id="signIn"
                                onClick={() => switchToAuth("login")}
                            >
                                Sign In
                            </button>
                        </>
                    )}
                </form>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
};

export default SentOtpModal;
