import React, { useState, useEffect, useRef } from "react";
//Styling File
import "./header.scss";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  sendOtpReset,
  loginOtpReset,
  createUserOtpReset,
} from "../../features/accountSlice";
//External Components
import { Link } from "react-router-dom";
import LoginModal from "../LoginModel/LoginModal";
import NewLoginModal from "../LoginModel/NewLoginModal";
import { fetchUserCity } from "../../actions/userLocationAction";
import LocationModal from "../LocationModal/LocationModal";
import { Navbar } from "react-bootstrap";
import Cookies from "js-cookie";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ListCityCalls from "../../ReduxHooks/ListCityCalls";

export default function Header({ handleUserLocationData }: any) {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  // const userLocation = localStorage.getItem("userLocation")
  //   ? JSON.parse(localStorage.getItem("userLocation") as string)
  //   : null;
  const [page, setPage] = useState(1);
  const userLocationString = Cookies.get("userLocation");
  const userLocation = userLocationString ? JSON.parse(userLocationString) : null;
  const [locationUser, setLocationUser] = useState(userLocation);
  const userInfo = useAppSelector((state) => state.userLogin);
  const { user_info } = userInfo;
  const loginOtp = useAppSelector((state) => state.loginOtp);
  const { success: otpLoginSuccess } = loginOtp;
  const sentOtp = useAppSelector((state) => state.sendOtp);
  const { success: isSendOtpSuccess } = sentOtp;
  const listCity = useAppSelector((state) => state.cityList);
  const { results, loading, success, error, next } = listCity;

  const handleSellProductClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault(); // Prevent the default behavior of anchor tag
    // Add your logic here
  };

  const handleMoreClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault(); // Prevent the default behavior of anchor tag
    // Add your logic here
  };
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [menuOpened, setMenuOpened] = useState(false);

  const toggleMenuOpened = () => {
    setMenuOpened(!menuOpened);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      headerRef.current &&
      !headerRef.current.contains(event.target as Node)
    ) {
      setMenuOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUserLocation = (data: any) => {
    setLocationUser(data);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = async () => {
    setShowModal(false);
  };
  //Login Modal Ends

  //Location Modal Starts
  const [showLocationModal, setShowLocationModal] = useState(false);
  const openLocationModal = () => {
    setShowLocationModal(true);
  };

  const closeLocationModal = () => {
    setShowLocationModal(false);
  };
  //Location Modal Ends

  window.addEventListener("scroll", function () {
    let navbar = document.querySelector("header");
    if (window.scrollY > 50) {
      navbar?.classList.add("sticky");
    } else {
      navbar?.classList.remove("sticky");
    }
  });

  useEffect(() => {
    if (otpLoginSuccess) {
      dispatch(sendOtpReset());
      dispatch(loginOtpReset());
      dispatch(loginOtpReset())
      dispatch(createUserOtpReset());
    }
  }, [otpLoginSuccess, dispatch]);

  useEffect(() => {
    if (showModal) {
      dispatch(sendOtpReset());
      
    }
  }, [showModal, dispatch]);

  useEffect(() => {
    if (showModal === false) {
      dispatch(loginOtpReset())
    }
  }, [showModal, dispatch])

  if (!userLocation) {
    fetchUserCity(setLocationUser);
  }

  return (
    <header ref={headerRef}>
      <ListCityCalls page={page} />
      <div className="header-container">
        <div className="logo">
          <Navbar.Brand>
            <Link to="/">
              <LazyLoadImage
                className="logo"
                src="https://buybackart-s3-media-storage.s3.ap-south-1.amazonaws.com/static/extras/logobuy-removebg-preview.webp"
                alt="logo"
              />
            </Link>
          </Navbar.Brand>
        </div>
        <nav className="none-mob">
          <ul>
            <li>
              <a className="" onClick={openLocationModal}>
                {locationUser ? (
                  <>
                    <i
                      className="fa fa-map-marker mx-1"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                      aria-hidden="true"
                    />
                    <span>{locationUser.city}</span>
                  </>
                ) : (
                  <>
                    <i
                      className="fa fa-map-marker mx-1"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                      aria-hidden="true"
                    />
                    <span>Select City</span>
                  </>
                )}
              </a>
              <LocationModal
                show={showLocationModal}
                handleClose={closeLocationModal}
                handleUserLocation={handleUserLocation}
                handleUserLocationData={handleUserLocationData}
                results={results}
              />
            </li>
            <li>
              <a
                href={
                  locationUser && locationUser.number
                    ? `tel:+91 ${locationUser.number}`
                    : "tel:+919310353308"
                }
              >
                Quick Call
              </a>
            </li>
            <li>
              <a onClick={handleSellProductClick} href="">
                Sell Product <i className=" fa fa-chevron-down"></i>
              </a>
              <ul className="submenu">
                <li>
                  <Link to="/sell-phones">Sell Phone</Link>
                </li>
                <li>
                  <Link to="/sell-laptops">Sell Laptop</Link>
                </li>
                <li>
                  <Link to="/sell-watches">Sell Watch</Link>
                </li>
                <li>
                  <Link to="/sell-tablets">Sell Tablets</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/be-partner">Be Partner</Link>
            </li>

            <li>
              <a onClick={handleMoreClick} href="">
                More <i className=" fa fa-chevron-down"></i>
              </a>
              <ul className="submenu">
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
                <li>
                  <Link to="/our-story">Our Story</Link>
                </li>
              </ul>
            </li>
            <li>
              {user_info && user_info.isAdmin && (
                <Link to="/inquiry">Inquiries</Link>
              )}
            </li>
            {user_info ? (
              <Link to="/profile">
                <button className="btn-primary btn btn-clr-dark">
                  {" "}
                  <i className="fa-user fa-solid mx-2"></i>
                  <span>{user_info.name}</span>
                </button>
              </Link>
            ) : (
              <>
                <button
                  className=" btn-clr-dark btn btn-success mx-3"
                  onClick={openModal}
                >
                  <span>Sign In</span>
                </button>
              
              </>
            )}
          </ul>
        </nav>
        {menuOpened && (
          <nav>
            <ul>
              <li>
                <a className="" onClick={openLocationModal}>
                  {locationUser ? (
                    <>
                      <i
                        className="fa fa-map-marker mx-1"
                        style={{ fontSize: "16px", marginRight: "5px" }}
                        aria-hidden="true"
                      />
                      <span>{locationUser.city}</span>
                    </>
                  ) : (
                    <>
                      <i
                        className="fa fa-map-marker mx-1"
                        style={{ fontSize: "16px", marginRight: "5px" }}
                        aria-hidden="true"
                      />
                      <span>Select City</span>
                    </>
                  )}
                </a>
                <LocationModal
                  show={showLocationModal}
                  handleClose={closeLocationModal}
                  handleUserLocation={handleUserLocation}
                  handleUserLocationData={handleUserLocationData}
                  results={results}
                />
              </li>
              <li>
                <a
                  href={
                    locationUser && locationUser.number
                      ? `tel:+91 ${locationUser.number}`
                      : "tel:+919310353308"
                  }
                >
                  Quick Call
                </a>{" "}
              </li>
              <li>
                <a href="" onClick={handleSellProductClick}>
                  Sell Product <i className=" fa fa-chevron-down"></i>
                </a>
                <ul className="submenu">
                  <li>
                    <Link to="/sell-phones">Sell Phone</Link>
                  </li>
                  <li>
                    <Link to="/sell-laptops">Sell Laptop</Link>
                  </li>
                  <li>
                    <Link to="/sell-watches">Sell Watch</Link>
                  </li>
                  <li>
                    <Link to="/sell-tablets">Sell Tablets</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/be-partner">Be Partner</Link>
              </li>

              <li>
                <a href="" onClick={handleMoreClick}>
                  More <i className=" fa fa-chevron-down"></i>
                </a>
                <ul className="submenu">
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/our-story">Our Story</Link>
                  </li>
                </ul>
              </li>
              <li>
                {user_info && user_info.isAdmin && (
                  <Link to="/inquiry">Inquiries</Link>
                )}
              </li>
              {user_info ? (
                <Link to="/profile">
                  <button className="btn-primary btn btn-clr-dark">
                    {" "}
                    <i className="fa-user fa-solid mx-2"></i>
                    <span>{user_info.name}</span>
                  </button>
                </Link>
              ) : (
                <>
                  <button
                    className=" btn-clr-dark btn btn-success mx-3"
                    onClick={openModal}
                  >
                    <span>Sign In</span>
                  </button>
                </>
              )}
            </ul>
          </nav>
        )}

        <div className="menu-icon" onClick={toggleMenuOpened}>
          <i className="fa-solid fa-bars"></i>
        </div>
        {/* <Navbar  expand="lg">
       
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link onClick={openLocationModal}>
              {locationUser ? (
                <>
                  <i className="fa fa-map-marker mx-1" style={{ fontSize: '16px', marginRight: '5px' }} aria-hidden="true" />
                  <span>{locationUser.city}</span>
                </>
              ) : (
                <>
                  <i className="fa fa-map-marker mx-1" style={{ fontSize: '16px', marginRight: '5px' }} aria-hidden="true" />
                  <span>
                    Select City
                  </span>
                </>
              )}
            </Nav.Link>
            <Nav.Link href={locationUser ? `tel:+91 ${locationUser.number}` : "tel:+919354388085"}>Quick Call</Nav.Link>
            <NavDropdown title="Sell Product" id="basic-nav-dropdown">
              <NavDropdown.Item href="/sell-phones">Sell Phone</NavDropdown.Item>
              <NavDropdown.Item href="/sell-laptops">Sell Laptop</NavDropdown.Item>
              <NavDropdown.Item href="/sell-watches">Sell Watch</NavDropdown.Item>
              <NavDropdown.Item href="/sell-tablets">Sell Tablets</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/be-partner">Be Partner</Nav.Link>
            <NavDropdown title="More" id="basic-nav-dropdown">
              <NavDropdown.Item href="/about">About</NavDropdown.Item>
              <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
              <NavDropdown.Item href="/blogs">Blogs</NavDropdown.Item>
              <NavDropdown.Item href="/our-story">Our Story</NavDropdown.Item>
            </NavDropdown>
            {user_info && user_info.isAdmin && (
              <Nav.Link href="/inquiry">Inquiries</Nav.Link>
            )}
          </Nav>
          {user_info ? (
            <Link to="/profile">
              <Button variant="primary" className="btn-clr-dark">
                <i className="fa-user fa-solid mx-2"></i>
                <span>{user_info.name}</span>
              </Button>
            </Link>
          ) : (
            <Button variant="success" className="btn-clr-dark mx-3" onClick={openModal}>
              <span>Sign In</span>
            </Button>
          )}
        </Navbar.Collapse>
        </Navbar> */}
        <LocationModal
          show={showLocationModal}
          handleClose={closeLocationModal}
          handleUserLocation={handleUserLocation}
          handleUserLocationData={handleUserLocationData}
          results={results}
        />
        <NewLoginModal show={showModal} handleClose={closeModal} />
      </div>
    </header>
  );
}
