import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import "./locationmodal.scss";
import React, { useState, useEffect } from "react";
import { CityList } from "../../state/fixtureState";
import { useAppSelector } from "../../app/hooks";
import ListCityCalls from "../../ReduxHooks/ListCityCalls";

interface LocationModalProps {
  show: boolean;
  handleClose: () => void;
  handleUserLocation: (data: any) => void;
  handleUserLocationData: (data: any) => void;
  results: any;
}

const LocationModal: React.FC<LocationModalProps> = ({
  show,
  handleClose,
  handleUserLocation,
  handleUserLocationData,
  results,
}) => {
  const [selectedCity, setSelectedCity] = useState<any>();
  const [cityNumber, setCityNumber] = useState<string | null>(null);
  const getOptions = (city_list: CityList[] | undefined) => {
    return city_list?.map((city: CityList) => ({
      // value: city.id,
      value: city.number,
      label: city.name,
    }));
  };

  const handleCityClick = (cityName: any, number: string) => {
    setSelectedCity(cityName);
    setCityNumber(number);
  };

  const handleInputChange = (option: any) => {
    console.log(option);

    setSelectedCity(option);
  };

  useEffect(() => {
    if (!selectedCity) {
      return;
    }

    const data = {
      city: selectedCity.label || selectedCity,
      number: selectedCity.value || cityNumber,
    };

    handleUserLocation(data);
    handleUserLocationData(data);
    const userLocation = { city: data.city, number: data.number };
    // localStorage.setItem("userLocation", JSON.stringify(userLocation));
    // Store userLocation in cookies
    Cookies.set("userLocation", JSON.stringify(userLocation), { expires: 1 });

    handleClose();
  }, [selectedCity, cityNumber]);

  return (
    <>
      <Modal
        size="lg"
        className="location-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Body className="location-modal-body">
          <div className="location-container">
            {results &&
              results.map((city: CityList) => (
                <div
                  key={city.id}
                  className={`city-container ${
                    selectedCity === city.name ? "selected" : ""
                  }`}
                  onClick={() => handleCityClick(city.name, city.number)}
                  style={{
                    backgroundColor:
                      selectedCity === city.name
                        ? "rgba(253, 226, 28, 0.2)"
                        : "white",
                  }}
                >
                  <div className="city-image">
                    <img src={city.image} alt="" />
                  </div>
                  <div
                    className="city-name"
                    style={{
                      color: selectedCity === city.name ? "#000" : "black",
                    }}
                  >
                    {city.name}
                  </div>
                </div>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default LocationModal;
