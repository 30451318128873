import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { BannerListState } from "../../state/bannerState";

export const bannerApi = createApi({
    reducerPath: "bannerApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/v1/fixtures" }),
    endpoints: (builder) => ({
        getBanners: builder.query<BannerListState, void>({
            query: () => `/web_banners/`,
        }),
    }),
});


export const { useGetBannersQuery } = bannerApi;